import { useTranslation } from 'react-i18next';
import { ArrowRight, SuccessLogo } from '../../ui/AllImages';
import React, { useState, useEffect } from 'react'
import { Modal, Col, Row, Form, Button } from 'react-bootstrap';
import { Box, LabelStyled, LazyImage, MainHeading, TextStyled } from '../../ui/Elements';
import { ContactButton, InputWithIcon } from '../../ui/CustomElements';
import axios from 'axios';

function Model() {
    const { t, i18n } = useTranslation()
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    const getModelForm = async () => {
        try {
            const response = await axios.post("https://albarraktrd.sa/api/public/api/need-help", { ...modelFormData, language: i18n.language })
            if (!response.data) {
                throw new Error('Response error plz check the response');
            }
            else {
                handleShowSuccessModal();
                setModelFormData({
                    name: "",
                    phone: "",
                    year: "",
                    model: "",
                    city: "",
                    message: "",
                });
                // console.log('Login API Response:', response);
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const [Errors, setErrors] = useState({
        name: "",
        phone: "",
        year: "",
        model: "",
        city: "",
        message: "",
    });
    const [modelFormData, setModelFormData] = useState({
        name: "",
        phone: "",
        year: "",
        model: "",
        city: "",
        message: "",
    });
    const modelFormHandle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setModelFormData((list) => {
            return { ...list, [name]: value }
        })
    }
    const validateForm = () => {
        const errors = {};
        if (modelFormData.name.trim() === "") {
            errors.name = t('Name_is_required');
        }
        if (modelFormData.phone.trim() === "" || !isValidPhone(modelFormData.phone)) {
            errors.phone = t('Invalid_phone_number');
        }
        if (modelFormData.year.trim() === "") {
            errors.year = t('year_is_required');
        }
        if (modelFormData.model.trim() === "") {
            errors.model = t('model_is_required');
        }
        if (modelFormData.city.trim() === "") {
            errors.city = t('city_is_required');
        }
        if (modelFormData.message.trim() === "") {
            errors.message = t('message_is_required');
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isValidPhone = (phone) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            getModelForm();
            console.log(modelFormData)
        }

    };

    return (
        <>
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <MainHeading size='24px'>{t('Model_heading')}</MainHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box>
                        <Form onSubmit={handleSubmit}>
                            <Row className='p-3'>
                                <Col lg={6} md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('Name')}</LabelStyled>
                                    <InputWithIcon type='text' name='name' value={modelFormData.name} placeholder={t('Name')} onChange={modelFormHandle} />
                                    <TextStyled className="error-message">{Errors.name}</TextStyled>
                                </Col>
                                <Col lg={6} md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('Contact_Number')}</LabelStyled>
                                    <InputWithIcon type='text' name='phone' value={modelFormData.phone} placeholder={t('Contact_Number')} onChange={modelFormHandle} />
                                    <TextStyled className="error-message">{Errors.phone}</TextStyled>
                                </Col>
                                <Col lg={6} md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('Year_of_Manufacturing')}</LabelStyled>
                                    <InputWithIcon type='text' name='year' value={modelFormData.year} placeholder={t('Year_of_Manufacturing')} onChange={modelFormHandle} />
                                    <TextStyled className="error-message">{Errors.year}</TextStyled>
                                </Col>
                                <Col lg={6} md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('Model')}</LabelStyled>
                                    <InputWithIcon type='text' name='model' value={modelFormData.model} placeholder={t('Model')} onChange={modelFormHandle} />
                                    <TextStyled className="error-message">{Errors.model}</TextStyled>
                                </Col>
                                <Col md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('City')}</LabelStyled>
                                    <InputWithIcon type='text' name='city' value={modelFormData.city} placeholder={t('City')} onChange={modelFormHandle} />
                                    <TextStyled className="error-message">{Errors.city}</TextStyled>
                                </Col>
                                <Col md={12} className='py-2'>
                                    <LabelStyled className='py-2'>{t('Message')}</LabelStyled>
                                    <Box className='box-model'>
                                        <textarea cols="" rows="6" name='message' value={modelFormData.message} placeholder={t('Message')} onChange={modelFormHandle}> </textarea>
                                        <TextStyled className="error-message">{Errors.message}</TextStyled>
                                    </Box>
                                </Col>
                                <ContactButton type='submit' className="my-1" content='end' icon={ArrowRight} label={t('Send')} />
                            </Row>
                        </Form>
                    </Box>
                </Modal.Body>
            </Modal>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <LazyImage src={SuccessLogo} />
                    <TextStyled>{t('Message_Sent_successfully')}</TextStyled>
                </Modal.Body>
                <Modal.Footer>
                    <ContactButton className="my-1" content='end' label={t('Close')} onClick={handleCloseSuccessModal} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Model;
